<template>
  <div>

    <h2 style="font-size: 1.5rem;">Verify OTP</h2>
    <div  style="margin-top: 3rem;">
      The OTP has been sent to <strong>{{channelAddress}}</strong>
    </div>
    <div  style="margin-top: 3rem;">
      <ValidationObserver slim v-slot="{ invalid }">
        <ValidationProvider
          rules="required|min:6"
          tag="div"
          class="field"
          v-slot="{ errors }"
        >
          <div class="control" style="display: flex; flex-direction: column;">
            <input
              style="max-width: 12rem;"
              name="OTP Code"
              class="input"
              type="text"
              placeholder="Enter OTP CODE"
              v-model="otpCode"
              maxlength="6"
            />
            <small class="has-text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <div class="buttons">
          <button
            class="button is-success"
            :disabled="invalid || $store.state.loading"
            @click="send"
          >
            Submit
          </button>

        </div>
      </ValidationObserver>
    </div>
    <div v-if="this.countDown" style="margin-top: 3rem;">You can request OTP again in {{countDown}} seconds</div>
    <div v-if="!this.countDown" style="margin-top: 3rem;">
      <button class="button" @click="logout()">
        Request OTP again
      </button>
    </div>


  </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import setCredentials from "@/activities/SetCredentials";
import axios from "axios";
import {mapState} from "vuex";
export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      countDown: Number,
      intervalId: null,
      otpInfo: null,
      channelAddress: null,
      otpCode: null
    }
  },
  created() {
    let otpInfoString = sessionStorage.getItem('otp_info');
    if (otpInfoString) {
      this.otpInfo = JSON.parse(otpInfoString);
    }
    this.channelAddress = this.otpInfo.channel == 'email' ? this.otpInfo.mfaEmail : this.otpInfo.channel == 'sms' ? this.otpInfo.mfaPhone : 'you';
  },
  mounted() {

    this.countDown = 30;
    this.intervalId = setInterval(() => {
      this.countDown--;
      if (!this.countDown) {
        clearInterval(this.intervalId)
      }
    }, 1000);

  },
  computed: {
    ...mapState(["username"]),
  },
  methods: {
    logout() {
      this.$store.commit("SET_GLOBAL_NOTIFICATION");
      sessionStorage.removeItem('otp_info')
      this.$router.push({ name: "login" });
    },
    async send() {
      this.$store.commit("SET_GLOBAL_NOTIFICATION");
      this.$store.commit("SET_LOADING", true);
      try {
        // let {data} = await axios.post(
        //   process.env.VUE_APP_API_URL + "otp/apply",
        //   {
        //     otpKey: this.otpInfo.otpKey,
        //     otp: this.otpCode
        //   }
        // );
        let {data} = await this.$store.dispatch("submitOTP", {
          otpKey: this.otpInfo.otpKey,
          otp: this.otpCode
        });
        let payload = {
          token: data.token,
          username: this.username.toLowerCase()
        };

        await setCredentials(payload);
        sessionStorage.removeItem('otp_info');
        this.$router.push({ name: "home" });
      } catch (error) {
        let errMsg = error;
        if (error.response?.data?.otpResult ?? false) {
          let otpResult = error.response?.data?.otpResult;
          if (otpResult == 'INVALID') {
            errMsg = 'Invalid OTP'
          } else if (otpResult == 'EXPIRED'){
            errMsg = 'OTP is expired. Please request again.'
          } else {
            errMsg = error.otpResult;
          }
        }
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: errMsg,
          notificationType: "error"
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }
    }
  }
};
</script>
